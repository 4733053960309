/**************************************************
 * Nombre:       Plan_Accion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2, Typography} from "@mui/material";
import plan from '../../../Recursos/plan_accion.png'
import Tarjeta_Margen from "./SubComponentes/Tarjeta_Margen";
import {theme} from "../../../Tema";
import {East} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {LINKBILLETERA} from "../../../Constantes";

const Plan_Accion_Home = () => {
    const {sCell, sTab} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid2
            container
            size={12}
            sx={{justifyContent: 'center', backgroundColor: '#fff', paddingTop: 12, pb: 12}}
            spacing={8}
        >

            <Grid2 container size={12} sx={{maxWidth: '1400px', px: 3, justifyContent: 'center'}}>

                <Grid2 container size={11}  sx={{alignItems: 'flex-start'}}>

                    <Grid2 container size={{xs: 12, sm: 6, md: 6}}>
                        <img src={plan} width={'100%'} height={'auto'}/>
                    </Grid2>

                    <Grid2 container size={{xs: 12, sm: 6, md: 6}} sx={{alignItems:  'flex-start'}} spacing={2}>
                        <Grid2 container size={12} sx={{marginTop: sCell ? 0 : 4}}>
                            <Typography sx={{fontSize: sTab? 16 : 24, lineHeight: 1.2, textAlign: sCell ? 'center' : 'left'}}>
                                FoxPlor invita a personas naturales a ser parte de nuestro crecimiento. Al adquirir
                                acciones, los socios obtienen un margen de ganancias acorde a su participación,
                                respaldados por una empresa innovadora en servicios digitales y financieros.
                            </Typography>
                        </Grid2>

                        <Grid2 container size={{xs: 12, sm: 6, md: 6}} sx={{marginTop: 4, marginLeft: 0}}>
                            <Tarjeta_Margen titulo={'Margen de Ganancia al dia'} descripcion={'1%'}/>
                        </Grid2>

                        <Grid2 container size={{xs: 12, sm: 6, md: 6}} sx={{marginTop: sCell ? 0 : 4, marginLeft: 0}}>
                            <Tarjeta_Margen titulo={'Rendimiento Mensual promedio'} descripcion={'29%'}/>
                        </Grid2>

                        <Grid2 container size={{xs: 12, sm: 6, md: 6}} sx={{marginTop: 0}}>
                            <Tarjeta_Margen titulo={'Retorno hasta'} descripcion={'200%'}/>
                        </Grid2>


                        <Grid2 container size={12} sx={{marginTop: 4}}>
                            <Typography sx={{
                                fontSize: 28,
                                lineHeight: 1.2,
                                color: theme.palette.primary.main,
                                fontWeight: 600
                            }}>
                                Retiros
                            </Typography>
                        </Grid2>
                        <Grid2 container size={12} sx={{marginTop: -2}}>
                            <Typography sx={{fontSize: 18, lineHeight: 1.2}}>
                                Los 15 y 30 de cada Mes
                            </Typography>
                        </Grid2>

                        <Grid2 container size={12} sx={{marginTop: 4, justifyContent: sCell ? 'center' : 'flex-start'}}>
                            <Button
                                fullWidth={sCell}
                               // onClick={() => navigate('/login/false')}
                                href={LINKBILLETERA + '/true'}
                                endIcon={<East/>}
                                sx={{
                                    background: 'linear-gradient(90deg, #4F1E00 0%, #FF6100 50.39%)',
                                    fontSize: 24,
                                    fontWeight: 400
                                }}
                            >Unete a FoxPlor</Button>
                        </Grid2>

                    </Grid2>



                </Grid2>

            </Grid2>

        </Grid2>
    )

}
export default Plan_Accion_Home