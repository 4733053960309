/**************************************************
 * Nombre:       Portada_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2, Typography} from "@mui/material";
import fondo_img from "../../../Recursos/img_fondo_home.png";
import fondo from "../../../Recursos/fondo_home.mp4";
import hombre from "../../../Recursos/hombre_portada.png";
import {theme} from "../../../Tema";
import {East} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {LINKBILLETERA} from "../../../Constantes";

const Portada_Home = () => {
    const {masSM, sCell, sTab} = useResponsive()
    const navigate = useNavigate()


    return (
        <Grid2
            container
            size={12}
            sx={{marginTop: -14}}
        >
            <video width={'100%'} height={sCell ? 940 : sTab ? 600 : 740}
                   style={{
                       objectFit: 'cover',
                       zIndex: 1
                   }}
                   id="background-video" autoPlay loop muted poster={`url(${fondo_img})`}>
                <source src={fondo} type="video/mp4"/>
            </video>


            <Grid2 container size={12} sx={{
                justifyContent: 'center',
                backgroundImage: sCell ? 'linear-gradient(72.27deg, #000000 0%, rgba(0, 0, 0, 0) 50%)' : 'linear-gradient(72.27deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
                // backgroundColor: 'pink',
                zIndex: 2,
                height: sCell ? 920 : sTab ? 590 : 740,
                marginTop: sCell ? -115 : sTab ? -74 : -92,
                alignItems: 'flex-start',
            }}>

                <Grid2 container size={11}
                       sx={{maxWidth: '1400px', px: 3, marginTop: sCell ? 14 : 20, justifyContent: 'center'}}>

                    <Grid2 container direction={'row-reverse'} size={12} sx={{alignItems: 'center'}}>

                        <Grid2 container size={{xs: 12, sm: 6}} sx={{justifyContent: 'flex-end'}}>
                            <img src={hombre} width={sCell ? '100%' : '75%'}/>
                        </Grid2>


                        <Grid2 container size={{xs: 12, sm: 6}} sx={{marginTop: sCell ? 4 : 0}}>

                            <Grid2 container size={12} sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                                <Typography sx={{
                                    fontSize: masSM ? 54 : 42,
                                    fontWeight: 600,
                                    color: theme.palette.primary.main,
                                    lineHeight: 1,
                                    textAlign: sCell ? 'center' : 'left'
                                }}>
                                    Conectando <span style={{color: '#fff'}}>negocios tradicionales</span> al mundo
                                    digital.
                                </Typography>
                            </Grid2>

                            <Grid2 container size={{xs: 12, ms: 6}}
                                   sx={{marginTop: 4, justifyContent: sCell ? 'center' : 'flex-start'}}>
                                <Typography sx={{
                                    fontSize: masSM ? 24 : 18,
                                    fontWeight: 400,
                                    color: '#fff',
                                    lineHeight: 1.2,
                                    textAlign: sCell ? 'center' : 'left'
                                }}>
                                    En FoxPlor, ayudamos a PYMES a crecer con soluciones de inversión, crédito y
                                    desarrollo,
                                    uniendo la innovación digital con las operaciones tradicionales.
                                </Typography>
                            </Grid2>

                            <Grid2 container size={12}
                                   sx={{marginTop: 4, justifyContent: sCell ? 'center' : 'flex-start'}}>
                                <Button
                                   // onClick={() => navigate('/login/false')}
                                    href={LINKBILLETERA + '/true'}
                                    endIcon={<East/>}
                                    sx={{
                                        background: 'linear-gradient(90deg, #4F1E00 0%, #FF6100 50.39%)'
                                    }}
                                >Únete a FoxPlor</Button>
                            </Grid2>

                        </Grid2>


                    </Grid2>

                </Grid2>

            </Grid2>


        </Grid2>
    )

}
export default Portada_Home