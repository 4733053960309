/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2} from "@mui/material";
import logo from '../../Recursos/logo.svg'
import {Login} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {LINKBILLETERA} from "../../Constantes";

const BarraPc = ({isHome}) => {
    const navigate = useNavigate()
    const {masSM, sCell} = useResponsive()

    return (
        <Grid2
            container
            size={12}
            sx={{justifyContent: 'center'}}
        >

            <Grid2
                container
                size={12}
                sx={{maxWidth: '1400px', px: 3, py: masSM ? 4 : 3, alignItems: 'center'}}
            >

                <Grid2 item size={{xs: 5, sm: 6}} sx={{marginTop: 1}}>
                    <img src={logo} width={masSM ? 220 : 150} height={'auto'}
                         onClick={() => navigate('/Home')}
                         style={{cursor: 'pointer'}}
                    />
                </Grid2>

                <Grid2 container item size={{xs: 7, sm: 6}} sx={{justifyContent: 'flex-end'}} spacing={2}>


                    {!sCell &&
                        <Grid2 container size={{xs: 6, sm: 7, lg: 3}} sx={{justifyContent: 'flex-end'}}>

                            <Button

                                // onClick={() => navigate('/login/false')}

                                href={LINKBILLETERA + '/true'}
                                color={'secondary'} variant={'outlined'}
                                sx={{
                                    border: 1,
                                    borderColor: '#ffffff80'
                                }}
                            >Registrarse</Button>

                        </Grid2>
                    }


                    <Grid2 container size={{xs: 9, sm: 5, lg: 3}} sx={{justifyContent: 'flex-end'}}>
                        <Button
                            //  onClick={() => navigate('/login/true')}
                            href={LINKBILLETERA + '/false'}
                            color={'secondary'}
                            endIcon={<Login/>}
                            sx={{
                                backgroundColor: '#ffffff40',
                                backdropFilter: 'blur(10px)',
                                color: '#fff',
                                border: 1,
                                borderColor: '#ffffff50'
                            }}>Ingresar </Button>
                    </Grid2>


                </Grid2>


            </Grid2>


        </Grid2>
    )

}
export default BarraPc