import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@fontsource/space-grotesk/300.css';
import '@fontsource/space-grotesk/400.css';
import '@fontsource/space-grotesk/500.css';
import '@fontsource/space-grotesk/700.css';
import {Grid2} from "@mui/material";
import Pagina from "./Pagina/Pagina";


function App() {
  return (
      <Grid2
          container
          size={12}
      >

        <Pagina/>

      </Grid2>
  );
}

export default App;
