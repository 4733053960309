/**************************************************
 * Nombre:       Seccion_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import Portada_Home from "./Componentes/Portada_Home";
import Caracteristicas_Home from "./Componentes/Caracteristicas_Home";
import Convenio_Visa_Home from "./Componentes/Convenio_Visa_Home";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import Plan_Accion_Home from "./Componentes/Plan_Accion_Home";


const Seccion_Home = () => {
    const {sCell, sTab, masSM} = useResponsive()

    return (
        <Grid2
            container
            size={12}

        >

            <Grid2 container size={12}>
                <Portada_Home/>
            </Grid2>

            <Grid2 container size={12} sx={{marginTop: sCell ? 12 : sTab ? 10 : 2}}>
                <Caracteristicas_Home/>
            </Grid2>

            <Grid2 container size={12} sx={{marginTop: !masSM ? -10 : 0}}>
                <Convenio_Visa_Home/>
            </Grid2>

            <Grid2 container size={12}>
                <Plan_Accion_Home/>
            </Grid2>


        </Grid2>
    )

}
export default Seccion_Home