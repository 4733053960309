/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import BarraPc from "./BarraPc";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {obtenerSeccion} from "../../Utilidades/obtenerSeccion";

const Barra = () => {
    const {masSM} = useResponsive()
    const location = useLocation()
    const [isHome, setIsHome] = useState(true)


    useEffect(() => {
        let sec = obtenerSeccion(location.pathname)

        if (sec === 'login') {
            setIsHome(false)
        } else {
            setIsHome(true)
        }

    }, [location]);
    return (
        <Grid2
            container
            size={12}
        >
            <BarraPc isHome={isHome}/>

        </Grid2>
    )

}
export default Barra