/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import Seccion_Home from "./Secciones/Seccion_Home";
import Barra from "./Barra/Barra";
import Footer from "./Footer";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

const Pagina = () => {

    return (
        <Grid2
            container
            size={12}
            sx={{backgroundColor: '#000'}}
        >

            <Router>
                <Grid2 container size={12} sx={{zIndex: 2}}>
                    <Barra/>
                </Grid2>

                <Grid2 container size={12} sx={{zIndex: 1}}>
                    <Routes>

                        <Route path={"/"} element={<Seccion_Home/>}/>
                        <Route path={"/Home"} element={<Seccion_Home/>}/>
                        <Route path={"*"} element={<Seccion_Home/>}/>

                    </Routes>
                </Grid2>

                <Grid2 container size={12} sx={{zIndex: 1}}>
                    <Footer/>
                </Grid2>

            </Router>

        </Grid2>
    )

}
export default Pagina