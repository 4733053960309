/**************************************************
 * Nombre:       Tarjeta_Smart_Idiomas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import icono from '../../../../Recursos/ic_shopyfox.svg'
import {theme} from "../../../../Tema";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";


const Tarjeta_Shopyfox = () => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid2
            container
            size={12}
            sx={{
                borderRadius: 6,
                border: 1,
                borderColor: '#fff',
                px: sCell ? 4 : 6,
                py: 4,
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                transition: 'all .2s ease-in-out',
                '&:hover':{
                    scale: '1.03',
                }

            }}
        >

            <Grid2 container size={{xs:12, sm: 12, md: 9}}>
                <Grid2 container size={12}>
                    <Typography sx={{fontSize: masSM ?  32 : 24, fontWeight: 600, color: '#fff'}}>
                        ShopyFox
                    </Typography>
                </Grid2>

                <Grid2 container size={{xs:12, sm: 12, md: 12}} sx={{marginTop: 1.5}}>
                    <Typography sx={{fontSize: 18, fontWeight: sCell ? 300 : 400, color: '#fff', lineHeight: 1.2}}>
                        Nuestro marketplace facilita <span
                        style={{color: theme.palette.primary.main, fontWeight: 500}}>transacciones seguras </span>con
                        criptomonedas y dinero fiat, ampliando el acceso a productos en una plataforma innovadora.
                    </Typography>
                </Grid2>
            </Grid2>

            <Grid2 container size={{xs:12, sm: 12, md: 3}} sx={{justifyContent: 'flex-end'}}>
                <img src={icono} width={masSM ? 90 : 60} height={'auto'}/>
            </Grid2>

        </Grid2>
    )

}
export default Tarjeta_Shopyfox