/**************************************************
 * Nombre:       Tarjeta_Margen
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import {theme} from "../../../../Tema";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";

const Tarjeta_Margen = ({titulo, descripcion}) => {
    const {sTab} = useResponsive()

    return (
        <Grid2
            container
            size={12}
            sx={{
                background: 'linear-gradient(123.05deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.02) 50%)',
                borderRadius: 4,
                boxShadow: 2,
                p: 2,
                transition: 'all .2s ease-in-out',
                '&:hover':{
                    scale: '1.03',
                    boxShadow: 4
                }

            }}
        >

            <Grid2 container size={{xs: 12, sm: 8, md: 12}} sx={{justifyContent: 'center'}}>
                <Typography sx={{textAlign: sTab ? 'left' : 'center', fontSize: sTab ? 12 : 18, lineHeight: 1.1, fontWeight: 500, height: 40}}>
                    {titulo}
                </Typography>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 4, md: 12}} sx={{justifyContent: 'center'}}>
                <Typography sx={{
                    textAlign: 'center',
                    fontSize: sTab ? 24 : 52,
                    color: theme.palette.primary.main,
                    fontWeight: 600
                }}>{descripcion}</Typography>
            </Grid2>

        </Grid2>
    )

}
export default Tarjeta_Margen