/**************************************************
 * Nombre:       Caracteristicas_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2} from "@mui/material";
import Plataforma_Accion_Credito from "./SubComponentes/Plataforma_Accion_Credito";
import Tarjeta_Smart_Idiomas from "./SubComponentes/Tarjeta_Smart_Idiomas";
import Tarjeta_Shopyfox from "./SubComponentes/Tarjeta_Shopyfox";
import Tarjeta_Turismo from "./SubComponentes/Tarjeta_Turismo";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Caracteristicas_Home = () => {
    const {sCell, sTab} = useResponsive()

    return (
        <Grid2
            container
            size={12}
            sx={{
                background: sCell ? 'linear-gradient(0deg, rgba(0,0,0,1) 88%, rgba(0,0,0,0.6306897759103641) 96%, rgba(0,0,0,0.21052170868347342) 99%, rgba(0,0,0,0) 100%)'
                    : sTab ? 'linear-gradient(0deg, rgba(0,0,0,1) 93%, rgba(0,0,0,0.6306897759103641) 96%, rgba(0,0,0,0.21052170868347342) 98%, rgba(0,0,0,0) 100%)'
                        : 'linear-gradient(0deg, rgba(0,0,0,1) 82%, rgba(0,0,0,0.6306897759103641) 93%, rgba(0,0,0,0.21052170868347342) 98%, rgba(0,0,0,0) 100%)',
                marginTop: -20,
                zIndex: 2,
                marginBottom: 20,
                justifyContent: 'center'
            }}
        >
            <Grid2
                container
                size={12}
                sx={{maxWidth: '1400px', px: 3, marginTop: sTab ? 14 : 10, justifyContent: 'center'}}
            >

                <Grid2
                    container
                    size={11}
                    spacing={3}
                >
                    <Grid2 container size={12} sx={{marginBottom: 12}}>
                        <Plataforma_Accion_Credito/>
                    </Grid2>

                    <Grid2 container size={{xs:12, sm: 6, lg: 6}}>
                        <Tarjeta_Smart_Idiomas/>
                    </Grid2>

                    <Grid2 container size={{xs:12, sm: 6, lg: 6}}>
                        <Tarjeta_Shopyfox/>
                    </Grid2>

                    <Grid2 container size={12}>
                        <Tarjeta_Turismo/>
                    </Grid2>


                </Grid2>
            </Grid2>
        </Grid2>
    )

}
export default Caracteristicas_Home