/**************************************************
 * Nombre:       Convenio_Visa_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import convenio from '../../../Recursos/convenio.svg'
import tarjeta from '../../../Recursos/tarjeta_visa.png'
import {theme} from "../../../Tema";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Convenio_Visa_Home = () => {
    const {sCell, sTab, masSM} = useResponsive()

    return (
        <Grid2
            container
            size={12}
            sx={{backgroundColor: '#fff', justifyContent: 'center', pb: 12}}
        >

            <Grid2 container size={12}
                   sx={{maxWidth: '1400px', px: 3, justifyContent: 'center', marginTop: !masSM ? 10 : 20}}>

                <Grid2 container size={11}
                       sx={{
                           background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%)',
                           border: 1,
                           borderColor: '#00000010',
                           borderRadius: 6,
                           px: sCell ? 6 : 8,
                           py: 6
                       }}

                >

                    <Grid2 container size={{xs: 12, sm: 7, md: 7}}>

                        <Grid2 container size={12}>
                            <img src={convenio} width={220} height={'auto'}/>
                        </Grid2>

                        <Grid2 container size={12} sx={{marginTop: 4}}>
                            <Typography
                                sx={{fontSize: !masSM ? 18 : 24, fontWeight: 500, lineHeight: 1.1}}
                            >Gracias a nuestro <span
                                style={{color: theme.palette.primary.main}}>convenio con VISA </span>,
                                FoxPlor permitirá a los usuarios retirar
                                sus ganancias de forma ágil y confiable, facilitando el acceso inmediato a sus
                                fondos.</Typography>
                        </Grid2>

                    </Grid2>

                    <Grid2 container size={{xs: 12, sm: 5, md: 5}}
                           sx={{justifyContent: 'center', marginTop: sCell ? 14 : 0}}>

                        <img src={tarjeta} width={sCell ? '150%' : sTab ? '100%' : '100%'} height={'auto'}
                             style={{margin: sCell ? -110 : sTab ? 0 : -140}}/>


                    </Grid2>


                </Grid2>


            </Grid2>


        </Grid2>
    )

}
export default Convenio_Visa_Home