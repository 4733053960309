/**************************************************
 * Nombre:       Plataforma_Accion_Credito
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import icono from "../../../../Recursos/ic_bolsa.svg";

const Plataforma_Accion_Credito = () => {

    return (
        <Grid2
            container
            size={12}
            sx={{justifyContent: 'center'}}
        >

            <Grid2 container size={12} sx={{justifyContent: 'center'}}>
                <img src={icono} width={60} height={'auto'}/>
            </Grid2>

            <Grid2 container size={12} sx={{justifyContent: 'center', marginTop: 1}}>
                <Typography sx={{fontSize: 32, color: '#fff', fontWeight: 600,textAlign: 'center', lineHeight: 1.1}}>Plataforma de Acción y
                    Crédito</Typography>
            </Grid2>

            <Grid2 container size={{xs:12, sm: 8, lg: 4}} sx={{justifyContent: 'center', marginTop: 1.5}}>
                <Typography sx={{fontSize: 18, color: '#fff', fontWeight: 400, textAlign: 'center', lineHeight: 1.1}}>
                    Accede a financiamiento ágil y personalizado para hacer crecer tu empresa. FoxPlor facilita
                    el camino hacia tus metas empresariales.
                </Typography>
            </Grid2>

        </Grid2>
    )

}
export default Plataforma_Accion_Credito