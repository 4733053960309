/**************************************************
 * Nombre:       Tarjeta_Smart_Idiomas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid2, Typography} from "@mui/material";
import icono from '../../../../Recursos/ic_avion.svg'
import {theme} from "../../../../Tema";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";


const Tarjeta_Turismo = () => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid2
            container
            size={12}
            sx={{
                borderRadius: 6,
                border: 1,
                borderColor: '#fff',
                px: sCell ? 4 : 6,
                py: 4,
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                transition: 'all .2s ease-in-out',
                '&:hover':{
                    scale: '1.03',
                }

            }}
        >

            <Grid2 container size={{xs: 12, sm: 8, md: 8}}>
                <Grid2 container size={12}>
                    <Typography sx={{fontSize: masSM ? 32 : 24, fontWeight: 600, color: '#fff', lineHeight: 1.1}}>
                        Turismo con intercambios
                    </Typography>
                </Grid2>

                <Grid2 container size={12} sx={{marginTop: 1}}>
                    <Typography sx={{fontSize: 18, fontWeight: sCell ? 300 : 400, color: '#fff', lineHeight: 1.2}}>
                        FoxPlor conecta a viajeros con servicios turísticos nacionales e internacionales, ofreciendo
                        <span
                            style={{color: theme.palette.primary.main, fontWeight: 500}}> experiencias únicas
                            </span> y apoyando a proveedores locales en cada destino.


                    </Typography>
                </Grid2>
            </Grid2>

            <Grid2 container size={{xs: 12, sm: 4, md: 4}} sx={{justifyContent: 'flex-end'}}>
                <img src={icono} width={ masSM ? 340 : 240} height={'auto'}/>
            </Grid2>

        </Grid2>
    )

}
export default Tarjeta_Turismo